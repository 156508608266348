(function () {

    angular.module('app').constant('coinTypes', [{
            text: '₪',
            value: '1'
        },
        {
            text: '$',
            value: '2'
        },
        {
            text: '€',
            value: '3'
        }
    ]);

})();