
angular.module('app').component('payment', {
    controllerAs: '$ctrl',
    controller: ['contextService', '$routeParams', '$mdDialog', '$scope', paymentCtrl],
    templateUrl: 'app/colel/payment/payment.component.html',
    bindings: {
        donationAmount: '<',
        coinType: '<',
        multiplier: '<'
    }
})

// paymentCtrl.$inject = ['contextService', '$routeParams', '$mdDialog', '$scope'];

function paymentCtrl(contextService, $routeParams, $mdDialog, $scope) {
    var vm = this;

    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    vm.paymentsArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    vm.coinTypeList = [
        {
            text: '₪',
            value: '1'
        },
        {
            text: '$',
            value: '2'
        },
        {
            text: '€',
            value: '3'
        }
    ];

    vm.saveTempDonation = function (newTran) {
        return contextService.addNewTransaction(newTran)
    }

    function buildPaymentObj(months, amount) {
        return {
            quantity: months,
            text: months === '1' ? 'ללא תשלומים' : 'חלוקת תרומה ל' + months + ' חודשים' + '(' + vm.selectedCoinType.text + Math.round(amount / months) + ')'
        }
    }

    vm.updateName = function() {
        if (vm.shouldUpdateNameForReceipt) {
            setTimeout(function() {vm.shouldUpdateNameForReceipt = false}, 5000)
            vm.donation.name = vm.donation.nameForReceipt;
        }
    }

    vm.onDonationAmountChange = function () {
        vm.payments = vm.paymentsArr.map(function (months) {
            return buildPaymentObj(months, vm.donation.donationAmount);
        });
    }

    $scope.redirect = function () {
        vm.donation.coin = vm.selectedCoinType.value;
        vm.donation.partner = (vm.partner || {})._id;
        vm.donation.inviter = $routeParams.l;
        vm.donation.colelId = vm.colel.id;
        vm.donation.payments = Number(vm.donation.payments);
        vm.donation.isHK = vm.donation.isHK === 'true' ? true : false;
        var dataAsParams = $.param(prepareData());
        vm.donation.payments = Math.min(vm.donation.payments, 12);
        vm.saveTempDonation(vm.donation).then(function (res) {
            window.location = 'https://icom.yaad.net/p/?' + dataAsParams;
            $mdDialog.cancel();
        }).catch(function (error) {
            console.log(error);
            windowReference.close();
            // $mdDialog.cancel();
            alert('תקלת אינטרנט! אנא נסו שנית');
        });
    }

    function prepareData() {

        var data = {
            action: 'pay',
            Masof: '4500385937',
            Info: 'תרומה',
            Amount: vm.donation.donationAmount,
            Order: vm.donation.order,
            sendemail: 'True',
            UTF8: 'True',
            MoreData: 'True',
            Coin: vm.donation.coin,
            ClientName: vm.donation.nameForReceipt,
            email: vm.donation.email,
            phone: vm.donation.phone,
            SendHesh: 'True',
            Tash: vm.donation.payments,
            FixTash: 'True',
        };

        if (vm.donation.isHK && vm.donation.payments > 1) {
            data.HK = 'True';
            data.OnlyOnApprove = 'True';
            data.Amount = vm.donation.donationAmount / Math.min(vm.donation.payments, 12);
        }

        return data;
    };

    vm.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    vm.$onInit = function () {
        vm.selectedCoinType = vm.coinTypeList[vm.coinType - 1];
        vm.shouldUpdateNameForReceipt = true;
        vm.order = contextService.getuuidv4();
        vm.donation = {
            donationAmount: vm.donationAmount,
            coin: vm.coinType,
            order: vm.order,
            payments: '1',
            multiplier: vm.multiplier,
            redirectURL: window.location.origin + window.location.pathname,
            isHK: 'false',
        };
        vm.payments = vm.paymentsArr.map(function (months) {
            return buildPaymentObj(months, vm.donation.donationAmount);
        });
        vm.partners = contextService.getPartners();
        vm.colel = contextService.getSelectedColel();
        if ($routeParams.partnerId) {
            vm.partnerId = $routeParams.partnerId;
            vm.partner = contextService.getPartnerByURL(vm.partnerId);
        }
    }
}