angular.module('app').component('createPartner', {
    controllerAs: '$ctrl',
    controller: createPartnerCtrl,
    templateUrl: 'app/colel/create-partner/create-partner.component.html',
})

createPartnerCtrl.$inject = ['coinTypes', '$scope', '$mdDialog', '$timeout', '$routeParams', 'contextService'];

function createPartnerCtrl(coinTypes, $scope, $mdDialog, $timeout, $routeParams, contextService) {
    var vm = this;

    function showPartnerLink(partner) {
        $mdDialog.show({
            controller: ['$scope', 'link', 'contextService', function($scope, link, contextService) {
                $scope.link = link;
                $scope.copyToClipboard = function() {
                    contextService.copyToClipboard(link);
                };

                $scope.close = function() {
                    $mdDialog.hide();
                }
            }],
            locals: { link: window.origin + '/' + $routeParams.colelId + '/' + partner.url },
            templateUrl: "app/colel/create-partner/create-partner-link.html",
            parent: angular.element(document.body),
            // targetEvent: evt,
            clickOutsideToClose: false,
            hasBackdrop: false,
            fullscreen: true
        });
        // .targetEvent(ev);
    }

    vm.onCreatePartner = function () {
        $timeout(function () {
            $mdDialog
                .show({
                    locals: { onClose: vm.onClose },
                    template: "<create-partner-form></create-partner-form>",
                    parent: angular.element(document.body),
                    // targetEvent: evt,
                    clickOutsideToClose: false,
                    hasBackdrop: false,
                    fullscreen: true // Only for -xs, -sm breakpoints.
                })
                .then(
                    function (newPartner) {
                        if (newPartner) {
                            showPartnerLink(newPartner);
                        }
                    },
                    function () { }
                );
            contextService.setModal($mdDialog);
        }, 300);
    }

    vm.$onInit = function () {

    }

}