angular.module('app').component('defaultDonation', {
    controllerAs: '$ctrl',
    controller: defaultDonationCtrl,
    templateUrl: 'app/colel/default-donation/default-donation.component.html',
    bindings: {
        passDonation: '&',
    }
})

defaultDonationCtrl.$inject = ['contextService'];

function defaultDonationCtrl(contextService) {
    var vm = this;

    vm.$onInit = function () {
        vm.defaultDonations = contextService.getSelectedColel().defaultDonations;
    }

    vm.passDefaultDonation = function(defaultDonation, evt) {
        vm.passDonation({
            donation: defaultDonation.amount,
            coinType: defaultDonation.coinType.value,
            payments: defaultDonation.payments,
            evt: evt
        }, 1)
    }
}