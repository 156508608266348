(function () {
  angular.module("app").factory("contextService", contextService);

  contextService.$inject = ["$http", "$mdToast"];

  function contextService($http, $mdToast) {
    var colels;
    var selectdColelId;
    var selectedColel;
    var currentCampaigns;
    var primeColel;
    var donationSum;
    var contributorsAmount;
    var capacitySum;
    var campaignDate;
    var transactions;
    var partners;
    var modal;
    var session;
    var primeColelBonus = false;

    function copyToClipboard(value) {
      var textArea = document.createElement("textarea");

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent';

      textArea.value = value;

      document.body.appendChild(textArea);

      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';

        $mdToast.show(
          $mdToast.simple()
            .textContent('כתובת האתר הועתקה למכשירך. הדבק ושתף')
            .parent('share')
            .position('top left')
            .hideDelay(3000)
        );
      } catch (err) {
        $mdToast.show(
          $mdToast.simple()
            .textContent(value)
            .parent('share')
            .position('top left')
            .hideDelay(8000)
        );
      }

      document.body.removeChild(textArea);
    }

    function setuuidv4() {
      session = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    function getPrimeColelBonus() {
      return primeColelBonus;
    }

    function setPrimeColelBonus(val) {
      primeColelBonus = val;
    }

    function getuuidv4() {
      return session
    }

    function setModal(_modal) {
      modal = _modal;
    }

    function getModal() {
      return modal;
    }

    function closeModal() {
      modal.cancel()
    }

    function setSelectedColel(_selectedColel) {
      selectedColel = _selectedColel;
    }

    function getSelectedColel() {
      return selectedColel;
    }

    function setPartners(_partners) {
      partners = _partners;
    }

    function getPartners() {
      return partners || [];
    }

    function getPartnerByURL(partnerURL) {
      return _.find(partners, function (c) {
        return c.url == partnerURL;
      });
    }

    function setTransactions(_transactions) {
      transactions = _transactions;
    }

    function getTransactions() {
      return transactions || [];
    }

    function getTransactionsLength() {
      return (selectedColel || {}).contributorsAmount;
    }

    function setAllColels(_colels) {
      for (var i = 0; i < _colels.length; i++) {
        if (_colels[i].id == 1) {
          _primeColel = _colels[i];
          _colels.splice(i, 1);
          _colels.unshift(_primeColel);
          break;
        }
      }
      colels = _colels;
    }

    function getAllColels() {
      return colels;
    }

    function sumOfColelDate(colelId) {
      var superColel = getColelById(colelId);

      superColel.numOfStudents = 0;
      superColel.hourInMonth = 0;
      superColel.numOfOtherStudents = 0;
      superColel.testInMonth = 0;

      colels.forEach(function (colel) {
        superColel.numOfStudents += colel.numOfStudents;
        superColel.hourInMonth += colel.hourInMonth;
        superColel.numOfOtherStudents += colel.numOfOtherStudents;
        superColel.testInMonth += colel.testInMonth;
      });

      return superColel;
    }

    function setCurrentCampaigns(_currentCampaigns) {
      currentCampaigns = _currentCampaigns;
    }

    function getCurrentCampaigns() {
      return currentCampaigns;
    }

    function getPrimeColelFromCampaigns() {
      return currentCampaigns.filter(function (campaign) {
        return campaign.colelId == 1;
      });
    }

    function getColelById(id) {
      return _.find(getAllColels(), function (colel) {
        return colel.id === id;
      });
    }

    function setSelectedColelId(_selectedColelId) {
      selectdColelId = _selectedColelId;
    }

    function getSelectedColelId() {
      return selectdColelId;
    }

    function isColelOnCampaign(colelId) {
      return _.find(currentCampaigns, function (c) {
        return c.colelId == colelId;
      });
    }

    function getCampaignFor(colelId) {
      return _.find(currentCampaigns, function (c) {
        return c.colelId == colelId;
      });
    }

    function addPartner(partner) {
      return $http.post('/partners', { data: partner });
    }

    function addNewTransaction(newTransaction) {
      return $http.post("/transactions/notConfirmed", { data: newTransaction });
    }

    function getPrimeColel() {
      return primeColel;
    }

    function setPrimeColel(_primeColel) {
      primeColel = _primeColel;
    }

    function getDonationsSum() {
      return donationSum;
    }

    function setDonationsSum(_donationsSum) {
      donationSum = _donationsSum;
    }

    function getContributorsAmount() {
      return contributorsAmount;
    }

    function setContributorsAmount(_contributorsAmount) {
      contributorsAmount = _contributorsAmount;
    }

    function getCapacitySum() {
      return capacitySum;
    }
    function setCapacitySum(_capacitySum) {
      capacitySum = _capacitySum;
    }

    function getCampaignDate() {
      return campaignDate;
    }
    function setCampaignDate(_campaignDate) {
      campaignDate = _campaignDate;
    }

    function getPercentanges() {
      if (primeColelBonus) {
        return (donationSum / primeColel.bonus) * 100
      } else {
        return (donationSum / capacitySum) * 100;
      }
    }

    return {
      getPrimeColelBonus: getPrimeColelBonus,
      setPrimeColelBonus: setPrimeColelBonus,
      setuuidv4: setuuidv4,
      getuuidv4: getuuidv4,
      closeModal: closeModal,
      getModal: getModal,
      setModal: setModal,
      setSelectedColel: setSelectedColel,
      getSelectedColel: getSelectedColel,
      setPartners: setPartners,
      getPartners: getPartners,
      getPartnerByURL: getPartnerByURL,
      setTransactions: setTransactions,
      getTransactions: getTransactions,
      getTransactionsLength: getTransactionsLength,
      setAllColels: setAllColels,
      getAllColels: getAllColels,
      getColelById: getColelById,
      setSelectedColelId: setSelectedColelId,
      getSelectedColelId: getSelectedColelId,
      getCurrentCampaigns: getCurrentCampaigns,
      setCurrentCampaigns: setCurrentCampaigns,
      isColelOnCampaign: isColelOnCampaign,
      getCampaignFor: getCampaignFor,
      addNewTransaction: addNewTransaction,
      getPrimeColel: getPrimeColel,
      setPrimeColel: setPrimeColel,
      getDonationsSum: getDonationsSum,
      setDonationsSum: setDonationsSum,
      getContributorsAmount: getContributorsAmount,
      setContributorsAmount: setContributorsAmount,
      getCapacitySum: getCapacitySum,
      setCapacitySum: setCapacitySum,
      getCampaignDate: getCampaignDate,
      setCampaignDate: setCampaignDate,
      getPercentanges: getPercentanges,
      getPrimeColelFromCampaigns: getPrimeColelFromCampaigns,
      sumOfColelDate: sumOfColelDate,
      addPartner: addPartner,
      copyToClipboard: copyToClipboard,
    };
  }
})();
