angular.module('app').component('campaignMobile', {
    controller: campaignMobileCtrl,
    controllerAs: '$ctrl',
    templateUrl: 'app/campaign-mobile/campaign-mobile.component.html',
    bindings: {
        campaign: '<',
        contributors: '<'
    }
})


campaignMobileCtrl.$inject = ["$interval", "contextService", "$http"];

function campaignMobileCtrl($interval, contextService, $http) {
    var vm = this;
    vm.time = new Date();

    vm.getPercentanges = function () {
        return ((vm.transSum() / vm.campaign.capacity) * 100)
    }

    vm.interval = $interval(updateTime, 1000)

    function updateTime() {

        vm.time = new Date(new Date(vm.campaign.endDate).getTime() - new Date().getTime());

        if (vm.time.getTime() < 1000) {
            vm.time = undefined;
            $interval.cancel(vm.interval);
            // $window.location.reload();
        }
    }


    vm.isDestinyAchieved = function () {
        return vm.campaign ? vm.transSum() >= vm.campaign.capacity : false;
    }

    vm.isCampaignAlive = function () {
        return vm.campaign && new Date(vm.campaign.endDate).getTime() >= new Date().getTime()
    }

    vm.isCampaignDone = function () {
        return vm.campaign && new Date(vm.campaign.endDate).getTime() < new Date().getTime()
    }

    vm.$onInit = function () {
        vm.transSum = function () { return 0 };
        if (vm.campaign._id) {

            if (vm.campaign.colelId == 1) {
                vm.transSum = contextService.getDonationsSum;
                vm.campaign.capacity = contextService.getCapacitySum();
            } else {
                $http.get('/campaigns/' + vm.campaign._id + '/transactions/sum').then(function (res) {
                    vm.transSum = function () { return res.data.sum; };
                }).catch(function (err) {
                    console.log(err)
                })
            }
        }
    }
}