angular.module('app').component('desc', {
    controllerAs: '$ctrl',
    controller: descCtrl,
    templateUrl: 'app/colel/desc/desc.component.html'
})

descCtrl.$inject = ['$sce', 'contextService', 'utilsService'];

function descCtrl($sce, contextService, utilsService) {
    var vm = this;
    vm.colel = contextService.getSelectedColel();

    vm.$onInit = function () {

        if (vm.colel.youtube) {

            vm.colel.youtube = "https://www.youtube.com/embed/" + vm.colel.youtube

            // vm.colel.youtube = vm.colel.youtube.replace("watch?v=", "v/");
        }

        vm.isMobile = utilsService.isMobile();

        vm.isDescOpen = false;

        if (typeof(vm.colel.overviewHTML) == "string") {
            vm.colel.overviewHTML = $sce.trustAsHtml(vm.colel.overviewHTML);
            vm.head = vm.colel.head;
            vm.phone = vm.colel.phone;
            vm.address = vm.colel.address;
        }
    }

    vm.onReadMoreClick = function () {        
        vm.isDescOpen = !vm.isDescOpen;
    }
}
