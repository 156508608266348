angular.module('app').component('root', {
    controller: rootCtrl,
    templateUrl: 'app/root.component.html'
})

rootCtrl.$inject = ['$scope', '$location', '$mdSidenav'];

function rootCtrl($scope, $location, $mdSidenav) {
    var vm = this;

    vm.currentNavItem = $location.path();

    // vm.user = USER;

    // Wait to the ng-view to be loaded
    $scope.$on('$viewContentLoaded', function () {
        vm.isViewContentLoaded = true;
    });


    vm.$onInit = function () {

        // if (LOGIN_ERROR) {
        //     $location.path("login");
        // };
    }
}