angular.module('app').component('createPartnerForm', {
    controllerAs: '$ctrl',
    controller: createPartnerFormCtrl,
    templateUrl: 'app/colel/create-partner/create-partner-form/create-partner-form.component.html'
})

createPartnerFormCtrl.$inject = ['$scope', '$mdDialog', '$routeParams', 'contextService'];

function createPartnerFormCtrl($scope, $mdDialog, $routeParams, contextService) {
    var vm = this;
    vm.clicked = false;
    vm.partner = {
        colelId: $routeParams.colelId,
    }
    vm.cancel = function () {
        $mdDialog.cancel();
    };

    vm.onPartnerCreate = function () {
        if (vm.regulations) {
            vm.clicked = true;
            contextService.addPartner(vm.partner).then(function (response) {
                vm.partner = response.data;
                $mdDialog.hide(vm.partner);
            });
        }
    }

    vm.$onInit = function () {

    }

}