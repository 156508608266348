angular.module('app').component('regulation',{
    controller: regulationCtrl,
    templateUrl: 'app/regulation/regulation.component.html'
})

regulationCtrl.$inject = [];

function regulationCtrl() {
    var vm = this;

    vm.$onInit = function() {
        
    }
}