angular.module("app").component("colel", {
  controllerAs: "$ctrl",
  controller: colelCtrl,
  templateUrl: "app/colel/colel.component.html"
});

colelCtrl.$inject = [
  "contextService",
  "utilsService",
  "$timeout",
  "$routeParams",
  "$mdDialog",
  "$http",
  "$rootScope",
  "$location",
  "$scope"
];

function colelCtrl(
  contextService,
  utilsService,
  $timeout,
  $routeParams,
  $mdDialog,
  $http,
  $rootScope,
  $location,
  $scope
) {
  var vm = this;

  vm.saveTempDonation = function (newTran) {
    newTran.colelId = vm.colel.id;
    return contextService.addNewTransaction(newTran);
  };

  vm.passDonationEvt = $rootScope.$on("passDonation", function (event, data) {
    vm.passDonation(1, 1, 1, event);
  });

  vm.passDonation = function (donation, coinType, payments, evt) {
    var multiplier = vm.colel.multiplier || 3;

    if (utilsService.isIOS()) {
      $timeout(function () {
        $mdDialog
          .show({
            template:
              "<payment donation-amount=" +
              donation +
              " coin-type=" +
              coinType +
              " payments=" +
              payments +
              " multiplier=" +
              multiplier +
              "></payment>",
            parent: angular.element(document.body),
            // targetEvent: evt,
            clickOutsideToClose: false,
            hasBackdrop: false,
            fullscreen: true // Only for -xs, -sm breakpoints.
          })
          .then(
            function (newTran) { },
            function () { }
          );
      }, 300);
    } else {
      $mdDialog
        .show({
          template:
            "<payment donation-amount=" +
            donation +
            " coin-type=" +
            coinType +
            " payments=" +
            payments +
            " multiplier=" +
            multiplier +
            "></payment>",
          parent: angular.element(document.body),
          targetEvent: evt,
          clickOutsideToClose: false,
          fullscreen: true // Only for -xs, -sm breakpoints.
        })
        .then(
          function () { },
          function () { }
        );
    }
  };

  if ($routeParams.donation || $routeParams.selectedCoinType) {
    vm.passDonation($routeParams.donation, $routeParams.selectedCoinType);
  }

  vm.$onInit = function () {
    vm.colel = contextService.getSelectedColel();
    contextService.setuuidv4();
    vm.PRODUCTION = window.location.origin.indexOf("localhost") === -1;
    if (vm.PRODUCTION) {
      window.LogRocket && window.LogRocket.init("pfod50/chomesh");
      LogRocket.identify(contextService.getuuidv4());
    }

    if (!vm.colel) {
      $location.path("/1");
    } else if ($routeParams.i) {
      $timeout(function () {
        $mdDialog
          .show({
            locals: { onClose: vm.onClose },
            template: "<thank-you-modal></thank-you-modal>",
            parent: angular.element(document.body),
            // targetEvent: evt,
            clickOutsideToClose: false,
            hasBackdrop: false,
            fullscreen: true // Only for -xs, -sm breakpoints.
          })
          .then(
            function (newTran) { },
            function () { }
          );
        contextService.setModal($mdDialog);
      }, 300);
    }
  };

  vm.$onDestroy = function () {
    vm.passDonationEvt();
  };
}
