(function () {

    angular.module('app').factory('utilsService', utilsService);


    utilsService.$inject = ['$window'];


    function utilsService($window) {

        function isMobile() {
            return $window.innerWidth < 700;
        }

        function isIOS () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        }

        return {
            isMobile: isMobile,
            isIOS: isIOS
        }
    }


})();