angular.module('app').component('login',{
    controller: loginCtrl,
    controllerAs: '$ctrl',
    templateUrl: 'app/login/login.component.html'
})


loginCtrl.$inject = [];

function loginCtrl() {
    var vm = this;

    vm.$onInit = function() {
        // vm.LOGIN_ERROR = LOGIN_ERROR;
    }

    // vm.onMessgeClick = function () {
    //     $('form').animate({height: "toggle", opacity: "toggle"}, "slow");
    // }
}