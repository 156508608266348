angular.module('app').component('partner', {
    controllerAs: '$ctrl',
    controller: partnerCtrl,
    templateUrl: 'app/colel/partner/partner.component.html',
    bindings: {
        partner: '<',
        search: '<'
    }
})

partnerCtrl.$inject = ['$routeParams', '$location'];

function partnerCtrl($routeParams, $location) {
    var vm = this;

    vm.shouldShow = function () {
        return !vm.search || vm.partner.name.indexOf(vm.search) != -1
    }

    vm.getPercentages = function () {
        return Math.min((vm.partner.balance / vm.partner.goal) * 100, 100);
    }

    vm.onClick = function (url) {
        $location.path(url)
    }

    vm.$onInit = function () {
        vm.colelId = $routeParams.colelId   
    }
}