angular.module('app').component('donateMobile', {
    controllerAs: '$ctrl',
    controller: donateMobileCtrl,
    templateUrl: 'app/colel/donate-mobile/donate-mobile.component.html',
    bindings: {
        colelId: '<',
        passDonation: '&'
    }
})

donateMobileCtrl.$inject = ['utilsService', 'contextService', 'coinTypes'];

function donateMobileCtrl(utilsService, contextService, coinTypes) {
    var vm = this;

    var DEFAULT_MULTIPLIER = 4;

    vm.$onInit = function () {

        vm.campagin = contextService.getCampaignFor(vm.colelId);
        vm.multiplier = vm.campagin ? vm.campagin.multiplier : DEFAULT_MULTIPLIER;
        vm.coinTypes = coinTypes;
        vm.selectedCoinType = vm.coinTypes[0];
    }
    vm.onDonate = function (donation, coinType, evt) {

        vm.passDonation({
            donation: donation,
            selectedCoinType: coinType,
            evt: evt
        })
    }

    vm.$onDestroy = function () {}

}