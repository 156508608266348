var app = angular.module("app", [
  "ngMaterial",
  "ngRoute",
  "ngSanitize",
  "angularSuperGallery",
  "ngDropdowns"
]);

app.filter("trusted", [
  "$sce",
  function($sce) {
    return $sce.trustAsResourceUrl;
  }
]);

app.config([
  "$routeProvider",
  "$mdThemingProvider",
  "$compileProvider",
  "$locationProvider",
  function(
    $routeProvider,
    $mdThemingProvider,
    $compileProvider,
    $locationProvider
  ) {

    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?|ftp|mailto|file|sms|tel|whatsapp):/
    );

    $routeProvider
      .when("/", {
        template: _getTagsFor("main"),
        resolve: {
          colels: getColels
        }
      })
      .when("/login", {
        template: _getTagsFor("login")
      })
      .when("/manage", {
        template: _getTagsFor("manage")
      })
      .when("/regulation", {
        template: _getTagsFor("regulation")
      })
      .when("/about", {
        templateUrl: "about.html"
      })
      .when("/contact", {
        templateUrl: "contact.html"
      })
      .when("/:colelId/:partnerId?", {
        template: _getTagsFor("colel"),
        resolve: {
          colel: getColelData
        }
      })
      .otherwise("/");

    $locationProvider.html5Mode(true);
    // Data to load before the controllers are up
    getColelData.$inject = ["$q", "$http", "contextService", "$location"];
    function getColelData($q, $http, contextService, $location) {
      var q = $q.defer();
      var splited = window.location.pathname.split("/");
      params = {
        colelId: splited[1],
        partnerId: splited[3]
      };
      var colelId = params.colelId;
      $http
        .get("/colels/" + colelId)
        .then(function(res) {
          if (!Object.keys(res.data).length) {
            $location.path("/");
            q.resolve();
            return res.data;
          } else {
            contextService.setSelectedColel(res.data);
            contextService.setPartners(res.data.partners);
            var url = "/transactions/colels/" + colelId;

            $http
              .get(url)
              .then(function(res) {
                contextService.setTransactions(res.data);
                if (contextService.getSelectedColel().campaignId) {
                  $http
                    .get("/transactions/colels/" + colelId + "/sum")
                    .then(function(res) {
                      var sum = res.data.sum;
                      var count = res.data.count;
                      var selectedColel = contextService.getSelectedColel();
                      selectedColel.sum = sum;
                      selectedColel.count = count;
                      contextService.setSelectedColel(selectedColel);
                      q.resolve();
                    });
                } else {
                  q.resolve();
                  return res.data;
                }
              })
              .catch(function(error) {
                q.resolve();
                console.log(error);
              });
          }
        })
        .catch(function(error) {
          console.log(error);

          q.resolve();
        });
      return q.promise;
    }

    getColels.$inject = ["$q", "$http", "contextService"];
    function getColels($q, $http, contextService) {
      var q = $q.defer();

      if (contextService.getAllColels()) {
        q.resolve();
      } else {
        $http.get("/primeColel").then(function(res) {
          contextService.setPrimeColel(res.data);
          var primeColel = contextService.getPrimeColel();
          // Get all colels from DB
          $http
            .get("/colels")
            .then(function(res) {
              contextService.setAllColels(res.data);
              // Get the sum of all donations within all campaigns
              if (
                primeColel.startDate &&
                primeColel.endDate
              ) {
                $http
                  .get("/transactions/colels/" + primeColel.colelId + "/sum")
                  .then(function(res) {
                    var donationSum = res.data.sum;
                    var contributorsAmount = res.data.count;
                    contextService.setDonationsSum(donationSum);
                    if (donationSum >= primeColel.capacity && primeColel.bonus) {
                      contextService.setPrimeColelBonus(true);
                    }
                    contextService.setCapacitySum(primeColel.capacity);
                    contextService.setContributorsAmount(contributorsAmount);
                    q.resolve();
                  })
                  .catch(function(err) {
                    console.log(err);
                    q.resolve();
                  });
              } else {
                q.resolve();
              }
            })
            .catch(function(err) {
              console.log(err);
              q.resolve();
            });
        });
      }

      return q.promise;
    }

    $mdThemingProvider
      .theme("default")
      .primaryPalette("cyan", {
        default: "400", // by default use shade 400 from the pink palette for primary intentions
        "hue-1": "100", // use shade 100 for the <code>md-hue-1</code> class
        "hue-2": "600", // use shade 600 for the <code>md-hue-2</code> class
        "hue-3": "A100" // use shade A100 for the <code>md-hue-3</code> class
      })
      .accentPalette("light-blue", {
        default: "800" // use shade 200 for default, and keep all other shades the same
      });
  }
]);

function _getTagsFor(route) {
  return "<" + route + "></" + route + ">";
}

function modifyImages(images) {
  var _images = [];

  for (var i = 0; i < images.length; ++i) {
    _images.push({
      image: images[i],
      thumbnail: images[i] + "/thumbnail"
    });
  }
  return _images;
}
