angular.module('app').component('chImage', {
    controllerAs: '$ctrl',
    controller: imageCtrl,
    templateUrl: 'app/colel/image/image.component.html',
    bindings: {
        colelId: '<',
        colelName: '<',
        images: '<'
    }
})

imageCtrl.$inject = ['$rootScope', 'contextService', 'utilsService', 'asgService'];

function imageCtrl($rootScope, contextService, utilsService, asgService) {
    var vm = this;
    
    var deregisterListener;

    vm.$onInit = function () {
        vm.images = angular.copy(vm.images)

        vm.isLoading = true;

        if (asgService.instances['colels-' + vm.colelId]) vm.isLoading = false;

        if (typeof (deregisterListener) == 'function') deregisterListener();

        deregisterListener = $rootScope.$on('ASG-first-image-colels-' + vm.colelId, function (evt) {
            vm.isLoading = false;
        })
        
        vm.locationListener = $rootScope.$on("$locationChangeStart", function (event, next, current) {
            // Prevent changes url by the modal to affect the app
            if (next.indexOf('#asg-') !== -1) {
                event.preventDefault();
            }
        });
    }

    var IMAGE_HEIGHT = utilsService.isMobile() ? 280 : 400;

    vm.galeryOptions = { // image load and autoplay info in console.log
        baseUrl: "/images/",
        autoplay: {
            enabled: true,
            delay: 5000
        },
        fields: {
            source: {
                image: "image"
            }
        },
        modal: {
            transition: 'rotateLR',
            menu: false,
            caption: false,
            size: 'contain', // contain, cover, auto, stretch
            keycodes: {
                exit: [27], // ESC
                playpause: [80], // p
                forward: [32, 39], // SPACE, RIGHT ARROW
                backward: [37], // LEFT ARROW
                first: [38, 36], // UP ARROW, HOME
                last: [40, 35], // DOWN ARROW, END
                fullscreen: [13], // ENTER
                menu: [77], // m
                caption: [67], // c
                help: [72], // h
                size: [83], // s
                transition: [84] // t
            },
            thumbnail: {
              height: 77,
              index: true
            }
        },
        image: {
            transition: 'rotateLR',
            size: 'cover', // contain, cover, auto, stretch
            height: IMAGE_HEIGHT, // height
            heightMin: 0, // min height
            heightAuto: {
                initial: true,
                onresize: false
            }
        }
    };

    vm.$onDestroy = function() {
        vm.locationListener();
    }

}