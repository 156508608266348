angular.module("app").component("chameshNavbar", {
  controller: navbarCtrl,
  templateUrl: "app/navbar/navbar.component.html"
});

navbarCtrl.$inject = ["$location", "$rootScope", "$routeParams"];

function navbarCtrl($location, $rootScope, $routeParams) {
  var vm = this;

  $rootScope.$on("$viewContentLoaded", function() {
    vm.isSidebarOpen = false;
  });

  vm.toggleNavBar = function() {
    vm.isSidebarOpen = !vm.isSidebarOpen;
  };

  vm.redirectToRightPage = function() {
    if (!$routeParams.colelId) {
      $rootScope.$evalAsync(function() {
        $location.path("/1");
      });
    } else {
      $rootScope.$emit("passDonation");
    }
  };

  function callback(event) {
    if ($routeParams.colelId) {
      $location.path('/');
    } else {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top - $(".navbar-container").height()
          },
          0,
          function() {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          }
        );
      } // End if
    }
  }

  vm.$onInit = function() {
    $("#colel-list").on("click", callback);
    $("#magazine").on("click", callback);
    $("#contact").on("click", callback);
  };
}
