angular.module("app").component("main", {
  controller: mainCtrl,
  templateUrl: "app/main/main.component.html"
});

mainCtrl.$inject = ["contextService", "$routeParams", "$timeout", "$mdDialog", "$location"];

function mainCtrl(contextService, $routeParams, $timeout, $mdDialog, $location) {
  var vm = this;

  vm.isCampaignOn = function() {
    return (
      new Date(vm.getPrimeColel().startDate) < new Date() &&
      new Date(vm.getPrimeColel().endDate) > new Date()
    );
  };
 
  vm.clickHandle = function () {
    $location.path('/1');
  }

  vm.$onInit = function() {
    vm.getSelectedColelId = contextService.getSelectedColelId;
    vm.getCurrentCampaigns = contextService.getCurrentCampaigns;
    vm.getPrimeColel = contextService.getPrimeColel;
  };
}
