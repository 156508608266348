angular.module('app').component('donation', {
    controllerAs: '$ctrl',
    controller: donationCtrl,
    templateUrl: 'app/colel/donation/donation.component.html',
    bindings: {
        campaign: '<',
        passDonation: '&',
        defaultDonation: '<'
    }
})

donationCtrl.$inject = ['coinTypes', '$scope', 'contextService'];

function donationCtrl(coinTypes, $scope, contextService) {
    var vm = this;
    
    vm.needToFill = {};

    vm.clickHandle = function (donationAmount, coinType, payments, evt) {

        if (donationAmount) {
            vm.needToFill = {}
            vm.passDonation({
                donation: donationAmount,
                coinType: coinType,
                payments: payments,
                evt: evt
            }, 1)
        } else {
            vm.needToFill = {
                borderTop: 'solid',
                borderBottom: 'solid',
                borderColor: 'red'
            }
            document.getElementById("id-for-focus").scrollIntoView();
        }
    }
    $scope.coinTypeList = coinTypes;

    $scope.selectedCoinType = $scope.coinTypeList[0];

    vm.multiplier = contextService.getSelectedColel().multiplier;
    vm.isDestinyAchieved = function () {
        return vm.campaign ? vm.transSum >= vm.campaign.capacity : false;
    }

    vm.$onInit = function () {

    }

}