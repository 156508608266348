angular.module('app').component('list', {
  controller: listCtrl,
  templateUrl: 'app/main/list/list.component.html'
});

listCtrl.$inject = ['contextService', 'utilsService', '$location'];

function listCtrl(contextService, utilsService, $location) {
  var vm = this;

  vm.getAllColels = contextService.getAllColels;
  vm.getSelectedColelId = contextService.getSelectedColelId;

  vm.onColelClick = function(colelId) {
    contextService.setSelectedColelId(colelId);
    $location.path("/" + colelId);
    // $('html, body').animate({scrollTop: $('#map').offset().top}, 'slow');
  };

  vm.$onInit = function() {
    if (utilsService.isMobile()) {
      // Fix mobile scroll
      $('.list-env').on('touchmove', function(e) {
        // $('.colel-list').css('pointer-events','none');
        $('.colel-list').focus();
      });
    }
  };
}
