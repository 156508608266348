angular.module('app').component('partners', {
    controllerAs: '$ctrl',
    controller: partnersCtrl,
    templateUrl: 'app/colel/partners/partners.component.html',
})

partnersCtrl.$inject = ['$routeParams', 'contextService', '$location'];

function partnersCtrl($routeParams, contextService, $location) {
    var vm = this;

    vm.$onInit = function () {
        var partnerURL = $routeParams.partnerId;
        vm.colelId = $routeParams.colelId;
        if (partnerURL) {
            partner = contextService.getPartnerByURL(partnerURL);
            if (partner) {
                vm.partner = partner;
            } else {
                $location.path(vm.colelId)
            }
        } else {  
            vm.partners = contextService.getPartners();
        }
    }
}