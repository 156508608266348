angular.module("app").component("tabs", {
  controllerAs: "$ctrl",
  controller: tabsCtrl,
  templateUrl: "app/colel/tabs/tabs.component.html"
});

tabsCtrl.$inject = ["contextService", "$routeParams", "$location"];

function tabsCtrl(contextService, $routeParams, $location) {
  var vm = this;
  vm.TABS = {
    DONATIONS: 'DONATIONS',
    ABOUT: 'ABOUT',
    PARTNERS: 'PARTNERS'
  };

  vm.transactionsLength = contextService.getTransactionsLength();
  vm.tab = $routeParams.partnerId ? vm.TABS.PARTNERS : vm.transactionsLength ? vm.TABS.DONATIONS : vm.TABS.ABOUT;
  vm.getPartners = function () {
    var partners = contextService.getPartners();
    return partners;
  }

  vm.onTabClick = function (tabClicked) {
    vm.tab = tabClicked;
    if (document.querySelector(".selected-tab")) {
      document
        .querySelector(".selected-tab")
        .classList.remove("selected-tab");
    }
    if (document.querySelector("#" + tabClicked)) {
      document.querySelector("#" + tabClicked).classList.add("selected-tab");
    }
  };
}
