angular.module('app').component('campaign', {
    controllerAs: '$ctrl',
    controller: campaignCtrl,
    templateUrl: 'app/colel/campaign/campaign.component.html',
    bindings: {
        capacity: '<',
        endDate: '<',
        sum: '<',
        passDonation: '&',
        title: '<',
        colelName: '<',
        contributors: '<',
        bonus: '<'
    }
})

campaignCtrl.$inject = ['$routeParams', 'coinTypes', '$http', '$interval', '$window', '$scope'];

function campaignCtrl($routeParams, coinTypes, $http, $interval, $window, $scope) {
    var vm = this;
    vm.needToFill = {};

    vm.getCorrectDigit = function(digit) {
        return digit ? (+digit > 9 ? digit : '0' + digit) : '00'
    }

    vm.clickHandle = function (donationAmount, coinType, evt) {

        if (donationAmount) {
            vm.needToFill = {}
            vm.passDonation({
                donation: donationAmount,
                coinType: coinType,
                evt: evt
            }, 1)
        } else {
            vm.needToFill = {
                borderTop: 'solid',
                borderBottom: 'solid',
                borderColor: 'red'
            }
        }
    }
    $scope.coinTypeList = coinTypes;

    $scope.selectedCoinType = $scope.coinTypeList[0];

    vm.isDestinyAchieved = function () {
        return vm.sum >= vm.capacity;
    }

    vm.isCampaignAlive = function() {
        return new Date(vm.endDate).getTime() >= new Date().getTime()
    }

    vm.isCampaignDone = function() {
        return new Date(vm.endDate).getTime() < new Date().getTime()
    }

    vm.shouldDisplayBonus = function() {
        return vm.isDestinyAchieved() && vm.bonus
    }

    vm.$onInit = function () {
        vm.isPrimeCampaign = $routeParams.colelId === '1';
    }

    vm.getPercentages = function (shouldLimit) {
        var percentages = (vm.sum / vm.capacity) * 100;
        if (shouldLimit) {
            percentages = Math.min(percentages, 100);
        }
        return percentages;
    }

    vm.interval = $interval(updateTime, 1000)

    function updateTime() {

        vm.time = new Date(new Date(vm.endDate).getTime() - new Date().getTime());

        if (vm.time.getTime() < 1000) {
            vm.time = undefined;
            $interval.cancel(vm.interval);
        }
    }
}