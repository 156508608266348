angular.module('app').component('thankYouModal', {
    controllerAs: '$ctrl',
    controller: ['contextService', '$routeParams', thankYouModalCtrl],
    templateUrl: 'app/colel/thank-you-modal/thank-you-modal.component.html',
})

function thankYouModalCtrl(contextService, $routeParams) {
    var vm = this;

    vm.cancel = function () {
        contextService.closeModal();
    };


    vm.$onInit = function () {
        vm.message = decodeURIComponent(atob($routeParams.m));
        if ($routeParams.i == 'false') {
            vm.failed = true;
        }
        
        if ($routeParams.l) {
            vm.modalUrl = window.location.origin + "?l=" + $routeParams.l;
        }
    }
}