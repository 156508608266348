angular.module('app').component('share', {
    controllerAs: '$ctrl',
    controller: shareCtrl,
    templateUrl: 'app/colel/share/share.component.html',
    bindings: {
        colelId: '<'
    }
})

shareCtrl.$inject = ['contextService'];

function shareCtrl(contextService) {
    var vm = this;

    vm.$onInit = function () {

    }

    vm.copyToClipboard = function () {
        contextService.copyToClipboard(window.location.href);
    }
}