angular.module('app').component('mainCampaign', {
    controller: mainCampaignCtrl,
    templateUrl: 'app/main/mainCampaign/mainCampaign.component.html'
})

mainCampaignCtrl.$inject = ['contextService', 'utilsService', '$window', '$interval', '$http', 'coinTypes', '$rootScope', '$location'];

function mainCampaignCtrl(contextService, utilsService, $window, $interval, $http, coinTypes, $rootScope, $location) {
    var vm = this;

    vm.isMobile = utilsService.isMobile;
    vm.interval = $interval(updateTime, 1000)

    function updateTime() {
        vm.time = new Date(new Date(vm.mainCampaign.primeColel.endDate).getTime() - new Date().getTime());
        if (vm.time.getTime() < 1000) {
            vm.time = {}
            $interval.cancel(vm.interval);
            // $window.location.reload();
        }
    }
    
    vm.getCorrectDigit = function(digit) {
        return digit ? (+digit > 9 ? digit : '0' + digit) : 0
    }

    vm.isDestinyAchieved = function () {
        return vm.mainCampaign ? vm.getDonationsSum() >= vm.mainCampaign.capacitySum : false;
    }

    vm.isCampaignAlive = function () {
        return vm.mainCampaign && new Date(vm.mainCampaign.primeColel.endDate).getTime() >= new Date().getTime()
    }

    vm.isCampaignDone = function () {
        return vm.mainCampaign && new Date(vm.mainCampaign.primeColel.endDate).getTime() < new Date().getTime()
    }

    vm.clickHandle = function () {
            $location.path('/1')
    }

    vm.$onInit = function () {
        vm.coinTypes = coinTypes;
        vm.selectedCoinType = vm.coinTypes[0];
        vm.isBonus = contextService.getPrimeColelBonus();
        vm.mainCampaign = {
            donationsSum: contextService.getDonationsSum(),
            contributorsAmount: contextService.getContributorsAmount(),
            capacity: contextService.getPrimeColel().capacity,
            primeColel: contextService.getPrimeColel(),
            percentanges: contextService.getPercentanges() || 0,
            bonus: contextService.getPrimeColel().bonus,
            title: contextService.getPrimeColel().title,
        };
    }
    
    if(!vm.isMobile()){        
        $("#colel").on('load', function () {
            var s = $("#secondLine");
            var pos = s.position();
            $(window).scroll(function () {
                var windowpos = $(window).scrollTop();
                
                if (windowpos >= pos.top + 60) {
                    s.addClass("stick");
                    $("#line3").css("margin-top", s.height());
                } else {
                    s.removeClass("stick");
                    $("#line3").css("margin-top", '-4px');
                }
            });
        });
    }
}