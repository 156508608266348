angular.module('app').component('chameshFooter', {
    controller: footerCtrl,
    templateUrl: 'app/footer/footer.component.html'
})

footerCtrl.$inject = ['$location', '$rootScope', '$scope'];

function footerCtrl($location, $rootScope, $scope) {
    var vm = this;
    vm.year = new Date().getFullYear()
    $rootScope.$on('$viewContentLoaded', function () {
        vm.isSidebarOpen = false;
    });

    // vm.user = USER;

    vm.redirectToRightPage = function () {
        if ($location.path().indexOf('colel') == -1) {
            $rootScope.$evalAsync(function () {
                $location.path('/colel/1')
            });
        } else {
            $rootScope.$emit('passDonation')
        }
    }

    // vm.locationListener = $rootScope.$on("$locationChangeStart", function (event, next, current) {
    //     if (next == current && $rootScope.isMapLoaded) {
    //         event.preventDefault();
    //     }
    // });

    vm.$onInit = function () {}

    // vm.$onDestroy = function () {
    //     vm.locationListener();
    // }

}