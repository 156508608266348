angular.module('app').component('contributes', {
    controllerAs: '$ctrl',
    controller: contributesCtrl,
    templateUrl: 'app/colel/contributes/contributes.component.html',
    bindings: {
        hideInput: '<'
    }
})

contributesCtrl.$inject = ['contextService', '$http', 'coinTypes', '$routeParams', '$location'];

function contributesCtrl(contextService, $http, coinTypes, $routeParams, $location) {
    var vm = this;

    var LIMIT = 10;
    vm.page = 1;
    vm.shouldShowLoadMore = false;
    vm.transactions = []
    vm.getCoin  = function(type) {
        return type ? coinTypes[type - 1].text : '₪';
    }
    
    vm.loadMore = function() {
        $http.get('/transactions/colels/' + vm.colelId + '?p=' + ++vm.page).then(function(res) {
            vm.transactions = res.data;
            vm.shouldShowLoadMore = !Boolean(vm.transactions.length % LIMIT);
        })
    }

    vm.$onInit = function () {
        vm.colelId = $routeParams.colelId;
        vm.isOnCampaign = contextService.isColelOnCampaign(vm.colelId);
        if (vm.hideInput) {
            var partnerId = $routeParams.partnerId;
            $http.get('/transactions/colels/' + vm.colelId + '/partners/' + partnerId)
            .then(function(res) {
                vm.transactions = res.data;
                vm.shouldShowLoadMore = !Boolean(vm.transactions.length % LIMIT);
                var partnerURL = $routeParams.partnerId;    
                vm.partnerName = contextService.getPartnerByURL(partnerURL).name;
            }).catch(function(err) {
                console.log(err);
                $location.path('/');
            })
        } else {
            vm.transactions = contextService.getTransactions();
            vm.shouldShowLoadMore = !Boolean(vm.transactions.length % LIMIT);
        }
    }
}