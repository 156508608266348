angular.module('app').component('partnerNotch', {
  controllerAs: '$ctrl',
  controller: partnerNotchCtrl,
  templateUrl: 'app/colel/partner-notch/partner-notch.component.html'
});

partnerNotchCtrl.$inject = ['$http', '$routeParams', 'contextService'];

function partnerNotchCtrl($http, $routeParams, contextService) {
  var vm = this;

  vm.$onInit = function() {
    var partnerURL = $routeParams.partnerId;
    var partner = contextService.getPartnerByURL(partnerURL);

    if (partner) {
      vm.partner = partner;
      vm.colelId = $routeParams.colelId;
    }
  };
}
