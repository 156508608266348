angular.module('app').component('map', {
  controller: mapCtrl,
  templateUrl: 'app/main/map/map.component.html'
});

mapCtrl.$inject = [];

function mapCtrl() {
  // var vm = this;

  // vm.$onInit = function() {
  //   mapService.init($scope);
  //   mapService.addMarkers(contextService.getAllColels(), $scope);
  // };

  // $scope.$watch(contextService.getSelectedColelId, function(newVal, oldVal) {
  //   if (oldVal !== newVal) {
  //     mapService.clickOnMarker(newVal);
  //   }
  // });

  // vm.$onDestroy = function() {
  //   mapService.destroy();
  // };
}
